import styled from '@emotion/styled';

export default styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.quartz};
  padding: 20px 15px 15px;
  overflow: hidden;
  picture {
    margin: -21px -16px 0;
    width: calc(100% + 32px);
    img {
      width: 100%;
    }
  }
`;
