import React from 'react';
import Button from './global/Button';
import Heading from './global/Heading';
import Picture from './global/Picture';
import Typography from './global/Typography';
import StyledPopularModelCard from './PopularModelCard.style';

const PopularModelCard = ({
  image,
  brand: { content: brand },
  model: { content: model },
  text: { content: text },
  link,
  ...p
}) => {
  return (
    <StyledPopularModelCard as={'a'} href={link.href} {...p}>
      <Picture
        images={{
          1: image.src,
        }}
        alt={`${brand} ${model || ''} picture`}
        width={273}
        height={164}
      />
      <Heading css={{ marginTop: 10 }} variant="sectionHeading2" as="h3">
        {brand}
      </Heading>
      <Typography
        css={{ marginTop: 8 }}
        variant="controlStrong"
        color="bahamaBlue"
        role="doc-subtitle"
      >
        {model}
      </Typography>
      <Typography
        css={{ marginTop: 20, marginBottom: 15 }}
        variant="control"
        color="trout"
        lineHeight="160%"
      >
        {text}
      </Typography>
      <Button
        large
        type="button"
        css={{ marginTop: 'auto', width: 'fit-content' }}
        arrow
      >
        {link.content}
      </Button>
    </StyledPopularModelCard>
  );
};
export default PopularModelCard;
